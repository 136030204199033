import { isDev, notProd } from "src/config";

export const CONTACT_SYNC = false;

export const SKIA_SHARE_PREVIEW = false && isDev;
export const MODAL_PRELOADING = false;
export const PHOTO_DETAIL_MODAL = true && notProd;
export const DEDUPLICATE_PHOTOS_OPTION = true && notProd;

export const ANIMATED_OPEN_GRAPH = true;

export const GRATITUDE = false;

// Stable flags (clean up eventually)
export const PROMPT_SHARE_AFTER_CREATE = true;
export const SHARE_PREVIEW = true;
export const GATHERING_QR_CODE = true;
export const SEND_ONLINE_UPDATES = true;
export const TAG_PROMPT = false;
export const PUBLIC_API_ENABLED = true;
export const INLINE_CREATE_ALBUM = true;
export const TOP_PHOTOS = true;
export const GATHERING_NEW_SHAREABLE = true;
export const PHOTO_DETAIL_RECAP = true;
export const PHOTO_REACTIONS = true;
export const BACKGROUND_UPLOAD = true;

// Flags we won't be enabling for a while
export const SHOWCASE_ENABLED = false && notProd;
export const SCHOOL_EMAIL_VERIFICATION = false && notProd;
export const SHARED_ELEMENT = false && isDev;

export const WIPE_IMAGE_CACHE_ON_START = false && isDev;
export const DISABLE_PERSIST_APOLLO_CACHE = false && isDev;
export const DISABLE_NEXTJS_SERVER_CACHE = false && isDev;
export const IS_GESTURE_HANDLER_WORKING = false && notProd;
export const FORCE_FILTER_LOADING = false && isDev;
export const DISABLE_LOG = false && isDev;
export const SANDBOX = false && isDev;
export const MUTUALS = false;
export const STORYBOOK = false && isDev;
export const TAG_BOUNDING_BOX = true && isDev;

/**
 * Simulates the API randomly breaking
 */
export const API_RANDOM_BREAKS = false && notProd;
export const UPLOAD_RANDOM_BREAKS = false && notProd;
